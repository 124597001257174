<template>
  <div class="w-form-reset-password">
    <div v-if="ssoProviders.length > 0" class="body-2 mb-2">
      Votre accès à {{ $metadatas.appName }} est administré depuis la connexion
      SSO de votre entreprise.<br />
      Connectez-vous simplement en cliquant sur le bouton
      <em>connexion</em>.<br />
      Identifiez-vous ensuite en utilisant vos identifiants de connexion
      professionnels. Une fois connecté, vous serez redirigé vers
      {{ $metadatas.appName }}
    </div>
    <div v-if="$voicer.canUseLocalSignin()">
      <v-divider v-if="ssoProviders.length > 0" class="mt-4 mb-4" />
      <div v-else class="body-1 mb-2">
        Pour toute demande de réinitialisation de mot de passe.
      </div>
      <div v-if="isEmailOnly">
        <v-form @submit.prevent="handleSubmit">
          <w-form-input-login v-model="login" class="mt-2" />
          <div class="mt-4 mb-4">
            <v-btn color="primary" type="submit" depressed
              >Réinitialiser le mot de passe</v-btn
            >
          </div>
          <v-divider />
          <div class="body-1 mt-4">
            En cas de problème, prenez contact avec notre support technique par
            email en écrivant à
            <w-support-email-address />.
          </div>
        </v-form>
      </div>
      <div v-else class="body-1">
        <div class="">
          Veuillez prendre contact avec
          <w-support-email-address />
          en nous indiquant votre identifiant.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WFormInputLogin from '~/components/WAuthenticator/login/WInputLogin'
import { getConfig } from '~/utils/voicer'

// @todo
// move away...
// duplicate code in winputlogin
const authPatterns = getConfig('authLocaleLoginFormat', ['email'])

const isEmailOnly = authPatterns.every((a) => a === 'email')

export default {
  components: {
    WFormInputLogin,
  },
  props: {
    ssoProviders: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    login: '',
  }),
  computed: {
    isEmailOnly: () => isEmailOnly,
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        login: this.login,
      })
    },
  },
}
</script>
