<template>
  <div
    class="rch-main"
    :class="{
      ['rch-main--mobile']: $voicer.isMobile,
      ['rch-main--ie']: $voicer.isIE,
      ['rch-main--is-not-ie']: $voicer.isIE === false,
    }"
  >
    <div class="rch-main__hodler rch-main__hodler" :style="mainStyle">
      <v-container class="rch-main__headline">
        <h1 class="white--text" :class="titleClasses">Lâche ta dédicace</h1>
        <div class="rch-main__hodler__actions">
          <div class="rch-main__hodler__actions__block">
            <v-btn
              large
              block
              depressed
              color="primary"
              @click="contactDialog = true"
            >
              <v-icon class="mr-1">mail</v-icon>
              Par mail
            </v-btn>
          </div>
          <div class="rch-main__hodler__actions__block">
            <v-btn
              href="tel:+33186860076"
              large
              block
              depressed
              color="primary"
            >
              <v-icon class="mr-1">phone</v-icon>
              Au 01 86 86 00 76
            </v-btn>
          </div>
        </div>
      </v-container>
      <v-container class="rch-main__player">
        <exc-button-play id="btn-play" :content="content" />
        <div id="main-arrow-holder">
          <div
            v-if="currentContent.description && $voicer.isDesktopOrTablet"
            class="rch-main__arrow"
          >
            <span
              class="white--text display-1"
              :class="{
                'rch-main__text--long': currentContent.description.length > 19,
              }"
              v-text="currentContent.description"
            />
          </div>
        </div>
      </v-container>
      <div class="rch-main__bottom-content">
        <img
          v-if="$voicer.isDesktopOrTablet && excGobImage"
          class="rch-exc-gob"
          :src="excGobImage"
        />
        <h2
          class="white--text"
          :class="titleClasses"
          v-text="currentContent.content"
        />
      </div>
    </div>
    <exc-contact-form v-model="contactDialog" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ExcButtonPlay from '../components/ExcButtonPlay'
import ExcContactForm from '../components/ExcContactForm'

import PageMixin from '~/mixins/page'
import { CONTENTS_DIRECTIVES } from '~/helper-rotative-contents'

const getContentDirective = (content) => {
  return CONTENTS_DIRECTIVES.find((c) => c.slug === content.slug) || {}
}

function cumulativeOffset(element) {
  let top = 0
  let left = 0
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0
    element = element.offsetParent
  } while (element)

  return {
    top,
    left,
  }
}

export default {
  name: 'RotativeContentHome',
  transitions: 'page',
  components: {
    ExcButtonPlay,
    ExcContactForm,
  },
  mixins: [PageMixin],
  data: () => {
    return {
      contactDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      isRootLoading: 'contents/isLoading',
      content: 'player/content',
    }),
    ...mapState({
      isLoading: 'contents/isLoading',
      contents: 'contents/defaultLoadedContents',
    }),
    currentContent() {
      return this.content
    },
    mainStyle() {
      const directive = getContentDirective(this.content)

      const getBackgroundImage = (directive) => {
        return this.$voicer.isMobile === false
          ? directive.background
          : directive.backgroundMobile
      }

      return {
        backgroundImage: `url(${getBackgroundImage(directive)})`,
      }
    },
    excGobImage() {
      const directive = getContentDirective(this.content)

      return directive.gob
    },
    titleClasses() {
      return {
        'display-2': this.$voicer.isDesktopOrTablet,
        'display-1 display-1__special-mobile': this.$voicer.isMobile,
      }
    },
  },
  watch: {
    currentContent() {
      this.computePosition()
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.computePosition)
  },
  mounted() {
    window.addEventListener('resize', this.computePosition)
    this.computePosition()
  },
  methods: {
    computePosition() {
      const btn = document.getElementById('btn-play')
      const arrow = document.getElementById('main-arrow-holder')

      if (btn && arrow) {
        const { top, left } = cumulativeOffset(btn)
        const btnWidth = btn.offsetWidth
        const btnHeight = btn.offsetHeight

        arrow.style.top = `${top + btnHeight / 3}px`
        arrow.style.left = `${left + btnWidth}px`
      }
    },
  },
}
</script>

<style>
.w-app--with-player .application--wrap main.v-content .v-content__wrap {
  height: 100% !important;
  padding-bottom: 0 !important;
}
.w-app--with-player.w-app--mobile
  .application--wrap
  main.v-content
  .v-content__wrap {
  padding-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
.rch-main {
  height: 100%;
  display: flex;

  .display-1__special-mobile {
    font-size: 32px !important;
  }

  .rch-main__hodler__actions__block {
    .v-btn--large {
      font-size: 18px;
    }
  }

  // mobile version
  &--mobile {
    .rch-main__hodler {
      &__actions {
        flex-wrap: wrap;

        &__block {
          flex: 0 0 100% !important;
          padding: 0 !important;
        }
      }
    }
  }

  &--ie {
    .rch-main__player {
      margin-top: 100px;
    }
    .rch-main__text--long {
      top: -75px !important;
      left: 32px !important;
    }

    @media screen and (max-width: 800px) {
      .rch-main__player {
        min-height: auto;
      }
      .rch-main__arrow {
        display: none;
      }
    }
    @media screen and (max-width: 1400px) {
      .rch-main__arrow {
        padding-top: 20px;
      }
    }
  }

  &--is-not-ie {
    .rch-main__text--long {
      top: -75px !important;
    }
  }

  .rch-main__hodler {
    height: 100%;
    width: 100%;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction: column;

    &__actions {
      display: flex;

      &__block {
        flex: 0 0 50%;

        &:first-child {
          padding-right: 10px;
        }

        &:last-child {
          padding-left: 10px;
        }
      }

      a,
      button {
        border: 4px white solid !important;
      }
    }
  }

  &__headline {
    text-align: center;
    flex-grow: 0;
    flex-basis: 0;

    h1 {
      text-transform: uppercase;
    }
  }

  #main-arrow-holder {
    position: fixed;
    z-index: 10;
  }

  &__arrow {
    width: 300px;
    height: 185px;
    background-image: url(/excentric/arrow.svg);
    background-repeat: no-repeat;
    background-position: center left;

    span {
      position: absolute;
      width: 100%;
      text-align: center;
      transform: rotateZ(11.5deg);
      left: 20px;
      text-transform: uppercase;
    }
  }

  &__player {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-width: 960px;
  }

  &__bottom-content {
    flex-grow: 0;
    position: relative;
    text-align: center;
    min-height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      position: relative;
      z-index: 1;
      text-transform: uppercase;
    }

    img {
      position: absolute;
      left: -190px;
      bottom: 0px;
      max-width: 480px;
      z-index: 0;
    }
  }

  &__text {
    &--long {
      top: -35px !important;
      left: 34px !important;
    }
  }
}
</style>
